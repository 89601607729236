var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-crear-plantilla","tabindex":"-1","role":"dialog","aria-hidden":"true","data-backdrop":"static","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Plantilla vehiculos")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm"},[_vm._m(0),_c('tbody',_vm._l((_vm.detalleLiqui),function(liqui,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(liqui.vehiculo.placa)+" ")]),_c('td',[_vm._v(" "+_vm._s(liqui.nOperacion)+" "),(liqui.area)?_c('b',[_vm._v("("+_vm._s(liqui.nArea)+")")]):_vm._e()]),_c('td',[_vm._v(_vm._s(liqui.vehiculo.tipo_vehiculo.nombre))]),_c('td',[_c('button',{staticClass:"btn bg-navy btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.printPlanilla(
                            liqui.tep_det_tarifa_interno_id,
                            liqui.vehiculo_id,
                            liqui.conductor_id,
                            liqui.conductor2_id,
                            liqui.tipo_vehiculo_id,
                            liqui.operacion,
                            liqui.area
                          )}}},[_c('i',{staticClass:"far fa-file-excel"})]),(liqui.planilla == null)?_c('button',{staticClass:"btn bg-indigo btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.upload(liqui.id)}}},[_c('i',{staticClass:"fas fa-upload"})]):_vm._e()]),_c('td',[(_vm.fileDoc && _vm.flag === liqui.id)?_c('div',[_c('button',{staticClass:"btn btn-sm bg-warning",attrs:{"type":"button"},on:{"click":function($event){return _vm.deletDoc()}}},[_c('i',{staticClass:"fas fa-trash-alt"})]),_c('button',{staticClass:"btn btn-sm bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.subirDoc(liqui)}}},[_c('i',{staticClass:"fas fa-upload"})])]):_c('div',[(_vm.flag === liqui.id)?_c('div',[_c('input',{ref:"file",refInFor:true,staticClass:"form-control-file",attrs:{"type":"file","id":"btnFile","accept":"application/msword,application/pdf,application/rtf,image/*"},on:{"change":_vm.getFile}})]):_vm._e()]),(liqui.planilla)?_c('button',{staticClass:"btn btn-success btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.downloadPlanilla(liqui.planilla.link_planilla)}}},[_c('i',{staticClass:"fas fa-download"})]):_vm._e(),(liqui.planilla)?_c('button',{staticClass:"btn btn-danger btn-sm",attrs:{"type":"button","disabled":liqui.planilla.verificado == '1' ||
                            liqui.planilla.verificado == true},on:{"click":function($event){return _vm.deletBd(liqui)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()]),_c('td',{attrs:{"align":"center"}},[(
                          _vm.$store.getters.can(
                            'tep.liquidacionesInternos.verificacion'
                          ) && liqui.planilla !== null
                        )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(liqui.planilla.verificado),expression:"liqui.planilla.verificado"}],attrs:{"type":"checkbox","disabled":liqui.planilla.verificado ||
                            liqui.planilla.verificado == 1},domProps:{"checked":Array.isArray(liqui.planilla.verificado)?_vm._i(liqui.planilla.verificado,null)>-1:(liqui.planilla.verificado)},on:{"change":[function($event){var $$a=liqui.planilla.verificado,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(liqui.planilla, "verificado", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(liqui.planilla, "verificado", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(liqui.planilla, "verificado", $$c)}},function($event){return _vm.cambiarCheck(liqui.planilla)}]}}):_vm._e()])])}),0)])])])]),_c('div',{staticClass:"modal-footer"})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Vehiculo")]),_c('th',[_vm._v("Operacion")]),_c('th',[_vm._v("Tipo Vehiculo")]),_c('th',[_vm._v("Servicios")]),_c('th',[_vm._v("Archivo")]),_c('th',[_vm._v("Verificacion")])])])
}]

export { render, staticRenderFns }