<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modal-form-crear-plantilla"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Plantilla vehiculos</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>Vehiculo</th>
                      <th>Operacion</th>
                      <th>Tipo Vehiculo</th>
                      <th>Servicios</th>
                      <th>Archivo</th>
                      <th>Verificacion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(liqui, index) in detalleLiqui" :key="index">
                      <td class="text-center">
                        {{ liqui.vehiculo.placa }}
                      </td>
                      <td>
                        {{ liqui.nOperacion }}
                        <b v-if="liqui.area">({{ liqui.nArea }})</b>
                      </td>
                      <td>{{ liqui.vehiculo.tipo_vehiculo.nombre }}</td>
                      <td>
                        <button
                          type="button"
                          class="btn bg-navy btn-sm"
                          @click="
                            printPlanilla(
                              liqui.tep_det_tarifa_interno_id,
                              liqui.vehiculo_id,
                              liqui.conductor_id,
                              liqui.conductor2_id,
                              liqui.tipo_vehiculo_id,
                              liqui.operacion,
                              liqui.area
                            )
                          "
                        >
                          <i class="far fa-file-excel"></i>
                        </button>
                        <button
                          type="button"
                          class="btn bg-indigo btn-sm"
                          v-if="liqui.planilla == null"
                          @click="upload(liqui.id)"
                        >
                          <i class="fas fa-upload"></i>
                        </button>
                      </td>
                      <td>
                        <div v-if="fileDoc && flag === liqui.id">
                          <button
                            type="button"
                            class="btn btn-sm bg-warning"
                            @click="deletDoc()"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>

                          <button
                            type="button"
                            class="btn btn-sm bg-success"
                            @click="subirDoc(liqui)"
                          >
                            <i class="fas fa-upload"></i>
                          </button>
                        </div>

                        <div v-else>
                          <div v-if="flag === liqui.id">
                            <input
                              type="file"
                              class="form-control-file"
                              ref="file"
                              id="btnFile"
                              @change="getFile"
                              accept="application/msword,application/pdf,application/rtf,image/*"
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          v-if="liqui.planilla"
                          class="btn btn-success btn-sm"
                          @click="
                            downloadPlanilla(liqui.planilla.link_planilla)
                          "
                        >
                          <i class="fas fa-download"></i>
                        </button>
                        <button
                          type="button"
                          v-if="liqui.planilla"
                          :disabled="
                            liqui.planilla.verificado == '1' ||
                              liqui.planilla.verificado == true
                          "
                          class="btn btn-danger btn-sm"
                          @click="deletBd(liqui)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                      <td align="center">
                        <input
                          v-if="
                            $store.getters.can(
                              'tep.liquidacionesInternos.verificacion'
                            ) && liqui.planilla !== null
                          "
                          type="checkbox"
                          @change="cambiarCheck(liqui.planilla)"
                          :disabled="
                            liqui.planilla.verificado ||
                              liqui.planilla.verificado == 1
                          "
                          v-model="liqui.planilla.verificado"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "TepLiquidacionPlantilla",
  components: {},
  data() {
    return {
      flag: null,
      form: {
        vehiculo_id: null,
        fecha_inicial: null,
        hora_inicial: null,
      },
      detalleLiqui: {},
      liquidacion: {},
      padre: {},
      fileDoc: null,
      tipo_control: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    async llenar_modal(item) {
      this.$parent.cargando = true;
      this.liquidacion = item;
      await axios
        .get("/api/tep/liquidacionesInternos/showDetallePlantilla", {
          params: {
            liqui_id: item.id,
          },
        })
        .then((response) => {
          this.$parent.cargando = false;
          this.detalleLiqui = response.data;
        });
    },
    upload(item) {
      this.fileDoc = null;
      this.flag = item;
    },
    getFile(e) {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.fileDoc = file;
        }
      }
    },

    async printPlanilla(
      tep_det_tarifa_interno_id,
      vehiculo_id,
      conductor_id,
      conductor2_id,
      tipo_vehiculo_id,
      tipo_operacion,
      area
    ) {
      this.$parent.cargando = true;
      let filtros = {
        tep_det_tarifa_interno_id,
        vehiculo_id,
        conductor_id,
        conductor2_id,
        tipos_vehiculo: tipo_vehiculo_id,
        sitio_id: this.liquidacion.sitio_id,
        empresa_id: this.liquidacion.empresa_id,
        fecha_ini: this.liquidacion.fecha_ini,
        fecha_fin: this.liquidacion.fecha_fin,
        tipo_operacion,
        area,
      };
      axios
        .get("/api/tep/liquidacionesInternos/planilla", {
          params: filtros,
        })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        let inputImage = document.getElementById("btnFile");
        inputImage.value = "";
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },
    deletDoc() {
      this.fileDoc = null;
    },
    subirDoc(liqui) {
      this.$parent.cargando = true;
      let formData = new FormData();
      formData.append(
        "tep_liquidacion_interno_id",
        liqui.tep_liquidacion_interno_id
      );
      formData.append("vehiculo_id", liqui.vehiculo_id);
      formData.append("operacion", liqui.operacion);
      formData.append("area", liqui.area);
      formData.append("link_planilla", this.fileDoc);
      axios
        .post("api/tep/liquidacionesInternos/subirPlanilla", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.llenar_modal(this.liquidacion);
          this.fileDoc = null;
          this.flag = null;
          this.$parent.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    downloadPlanilla(planilla) {
      window.open(this.uri_docs + planilla, "_blank");
    },

    deletBd(liqui) {
      this.$parent.cargando = true;
      axios
        .delete("/api/tep/liquidacionesInternos/destroyPlanilla", {
          params: {
            id: liqui.planilla.id,
          },
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "la planilla se eliminó correctamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.llenar_modal(this.liquidacion);
          this.fileDoc = null;
          this.flag = null;
          this.$parent.cargando = false;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    cambiarCheck(planilla) {
      axios
        .post("/api/tep/liquidacionesInternos/verificarDocumento", {
          planilla,
        })
        .then(() => {
          this.$parent.getIndex();
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vualeva a intentarlo..." + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = true;
        });
    },
  },
};
</script>
