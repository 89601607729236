<template>
  <div>
    <div class="modal-backdrop fade show" style="z-index:10000">
      <div class="row justify-content-center h-100">
        <div class="align-self-center ">
          <ScaleLoader
            class="text-center"
            color="#fff"
            :height="150"
            :width="10"
            margin="10px"
          />
          <h3 class="text-center text-light">Cargando...</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ScaleLoader } from "@saeris/vue-spinners";
export default {
  name: "Loading",
  components: {
    ScaleLoader,
  },
};
</script>
